<template>
  <div class="row px-4">
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      
      <label> <span @click='cancel()' class="text-capitalize cursor-pointer text-gray-900 font-semibold">
          Category</span> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Edit</span>
      </label>
      <h4 class="text-xl capitalize">Edit Category</h4>
    </div>
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
      <h4 class="font-bold text-xl py-2.5">Edit Category</h4>
      <div class="w-1/2 flex-col">
        <div>
          <label class="block">Category Name</label>
          <input type="text" class="px-2.5 py-2.5 border rounded-lg w-full" id="name" placeholder="type category name.."
            v-model="name" /> 
        </div>
        <div class="mt-2">
          <label class="block">Item’s Type</label>
          <t-select class="px-2.5 py-2.5" id="status" 
          v-model="item_name" 
          :options="options"
          >
          </t-select>
        </div>
        <div class="mt-2">
          <label class="block">Category BiSAC</label>
          <multiselect
                  v-model="category_bisac"
                  label="name"
                  track-by="name"
                  placeholder="type to find category bisac"
                  open-direction="bottom"
                  :options="items_category_bisac"
                  :searchable="true"
                  :loading="isLoadingSearch"
                  :close-on-select="true"
                  @search-change="asyncFind"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
        </div>
        <div class="mt-3 mb-4">
          <t-checkbox type="checkbox" id="is_active" v-model="is_active" />
          <label class="form-check-label" for="is_active">Is Active</label>
        </div>

        <div class="flex justify-start pb-3">
          <button class="bg-blue-700 text-white rounded-lg px-3 py-2" @click="updateCategory">Save</button>
          <button class="bg-white text-gray-500 rounded-lg border px-3 py-2 ml-4" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import API from '../../plugins/http';
import constant from '../../store/constant';
import { EventBus } from '../../event-bus'

const itemType = Object.entries(constant.ITEM_TYPES).map((val) => ({
  text: val[1],
  value: val[1].toLowerCase(),
}));

export default {
  name: 'EditCategoryBisac',
  data() {
    return {
      name: '',
      item_name: 'book',
      category_bisac: '',
      items_category_bisac: [],
      id_category_bisac_old: '',
      isLoadingSearch: false,
      is_active: false,
      options: [...itemType],
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.category.item,
      isError: (state) => state.category.isError,
      successMessage: (state) => state.category.successMessage,
      errorMessage: (state) => state.category.errorMessage,
    }),
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getCategory();
    },
    itemCategoryBisac: function() {
      if (!Object.keys(this.item).length) return;
      this.getCategoryBisac();
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    successMessage: function() {
      if (!this.successMessage) return;
      EventBus.$emit('show-toast', {
        message: this.successMessage,
        type: 'success',
      });
      this.$router.push('/category');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      EventBus.$emit('show-toast', {
        message: this.errorMessage,
        type: 'error',
      });
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Edit Category | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  created: function() {
    const categoryID = this.$route.params.id;
    this.fetchCategoryById({ id: categoryID });
    this.getCategoryBisac(categoryID);
  },
  methods: {
    ...mapActions('category', ['fetchCategoryById', 'editCategory']),
    ...mapActions('category_bisac', ['searchCategoryBisac']),

    async getCategory() {
      const data = this.item;
      this.name = data.name;
      this.item_name = data.item_type;
      this.is_active = data.is_active;
    },

    async getCategoryBisac(id) {
      await API.get('category-bisac/category/' + id)
        .then((res) => {
          this.id_category_bisac_old = res.data.data.id;
          this.category_bisac = res.data.data;
        })
        .catch((err) => {
          this.alertMsg(err.response.data.data.message, 'error');
        });
    },

    asyncFind(keyword) {
      if (!keyword) return;
      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          search: keyword,
        };

        this.searchCategoryBisac(payload)
          .then((response) => {
            this.items_category_bisac = response.data.data;
            this.isLoadingSearch = false;
          })
          .catch(() => {
            this.isLoadingSearch = false;
          });
      }, 600);
    },

    async updateCategory() {
      const categoryID = this.$route.params.id;
      let payload = {
        id: categoryID,
        item_name: this.item_name,
        name: this.name,
        category_bisac: this.category_bisac.id,
        is_active: this.is_active,
        id_category_bisac_old: this.id_category_bisac_old,
      };

      this.editCategory(payload);
    },
    cancel() {
      this.$router.push('/category');
    },
    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
